export default function ClanMap(){
    return(
    <>
        <div className="max-w-7xl mx-auto">
            <img src="/img/v2/tiger_map_bg.png" alt="Tiger Clan Map" />
            <div className="bg-birds_five bg-cover bg-bottom bg-no-repeat">
                <div className="max-w-4xl mx-auto py-8 px-8 lg:px-0">
                    <h2 className='font-Shojumaru text-4xl md:text-6xl text-clan_burgundy text-center'>Tiger Clan Map</h2>    
                    <p className="mt-4 text-xl text-black">As word is bond, utility is top priority but it doesn’t mean it has to be boring. Stay fresh with the Wu-Tiger Clan while reaping the benefits of being part of a forward thinking, web3 based, solutions-oriented family.</p>
                    <p className="mt-4 text-xl text-black">We are guided by our vision. The vision which has given us success . - We are here to create a village for the web3 world that is built and owned by its citizens and will be here forever. If you know you know.</p>
                </div>
            </div>
            
        </div>
        
    </>
    );
}