export default function StripClassic(){
    return(
    <>
        <div className="max-w-7xl bg-birds_two_b bg-contain bg-left-top bg-no-repeat mx-auto my-2 md:my-8">
            <div className='max-w-4xl mx-auto px-8 md:px-0'>
                <img src="/img/v2/cloud-tigers-classic.png" alt="Wu Tiger Clan" className='mx-auto w-full' />
            </div>
        </div>
        
    </>
    );
}