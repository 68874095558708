import './App.css';
import Homepage from './Homepage';
import Forthechildren from './Forthechildren';
import { Route, Routes } from "react-router-dom";


function App() {
  return (
    <Routes>
        <Route
          path="/"
          element={<Homepage />}
        />
        <Route 
          path="for-the-children" 
          element={<Forthechildren />} 
        />
        <Route 
          path="forthechildren" 
          element={<Forthechildren />} 
        />
        
        <Route 
          path="*" 
          element={<Homepage />} 
        />
      </Routes>
  );
}

export default App;
