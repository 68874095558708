import Marquee from "react-fast-marquee";
import watermark_1 from './img/ales-foundation-1.jpg';
import watermark_2 from './img/ales-foundation-2.jpg';
import watermark_3 from './img/ales-foundation-3.jpg';
import watermark_4 from './img/ales-foundation-4.jpg';
import poster from './img/for-the-children-2023.png';
export default function Forthechildren()
{
    return(
        <> 
        <div className="mx-auto">
                <div className="pb-5 mt-1 mb-0 overflow-hidden">
                    <Marquee
                        pauseOnHover={false}
                        gradient={false}
                        speed={30}
                        >
                        <img src={watermark_1} alt="one" className='h-96 w-auto rounded-xl p-5' />
                        <img src={watermark_2} alt="one" className='h-96 w-auto rounded-xl p-5' />
                        <img src={watermark_3} alt="one" className='h-96 w-auto rounded-xl p-5' />
                        <img src={watermark_4} alt="one" className='h-96 w-auto rounded-xl p-5' />
                    </Marquee>
                </div>
            </div>

            <section className="px-4 md:px-0 pt-6 my-0 text-center">
                <div className="max-w-3xl mx-auto text-center">
                    <h1 className='text-3xl md:text-4xl text-slate-800 font-Anton font-bold'>Become a <span className="text-m2_orange">FOR THE CHILDREN</span> Advocate </h1>
                    <h2 className="text-3xl text-slate-800 font-roboto mt-1">This is something we do for the children.</h2>
                    <div className="h-2 border-b border-black w-1/2 text-sm text-center mt-4 mb-8 mx-auto"></div>
                
                    <p className="text-2xl text-black font-Roboto mb-3 text-left">In December of last year, we helped feed 877 children in the Dominican Republic.</p>
                    <p className="text-2xl text-black font-Roboto mb-3 text-center">For The Children is embarking on our next mission on July 15, 2023 to the Dominican Republic. Our team is dedicated to making a difference in the lives of over 1000 children by providing them with much-needed nourishment and school supplies. This year, we are thrilled to incorporate an additional component by presenting bookbags, notebooks, pencils, and other essential materials that will help these children succeed in their education.</p>
                    <p className="text-2xl text-black font-Roboto text-center">We strongly believe that investing For The Children is investing in the future… and the future is web3. We are confident that our efforts will only become more impactful as they have the potential to generate a ripple effect that will be felt for generations. By providing these children with the tools they need to learn and grow, we are empowering them to become the leaders of tomorrow.</p>

                    <p className="text-2xl text-black font-Roboto text-center">Join us in making a positive impact in the world by supporting these young minds as they embark on their educational journeys.</p>
                    
                    {/* <div className="text-center mt-10 mb-12"><a href="https://app.manifold.xyz/c/forthechildren" target="_blank" rel="noopener noreferrer" className="hover:bg-m2_orange bg-black text-white py-5 px-10 rounded-lg text-3xl">Purchase on Manifold</a></div> */}
                    <div className="text-2xl text-slate-800 font-roboto mt-8 text-center mb-8">
                        <p className="font-bold">You can contribute what you can here:</p>
                        <p>Paypal: <span className="text-m2_orange">ForChildrenWorldwide@gmail.com</span></p>
                        <p>Or</p>
                        <p>ETH wallet: <strong className="break-words text-xl text-m2_orange">0x88E3D86F94776DCC43Bc8a29C58267687446cbc3</strong></p>
                    </div>
                    {/* <div className="h-2 border-b border-slate-800 w-1/2 text-sm text-center mt-4 mb-8 mx-auto"></div> */}


                    {/* <h3 className="text-2xl text-slate-800 font-roboto mt-8 text-center mb-4">Although you have the option to support by buying this NFT, it's important to note that there are gas fees linked to the transaction that might exceed the NFT's price. Contributing directly is also possible.</h3> */}
                </div>
            </section>
            <section className="px-4 md:px-0 my-0 text-center">
                <div className="max-w-xl mx-auto text-center px-4 md:px-0">
                    <img src={poster} alt="Poster" className='rounded-xl' />
                    </div>
            </section>
            <section className="px-4 md:px-0 pt-6 my-0 text-center">
                <div className="max-w-3xl mx-auto text-center">
                    {/* <h3 className="text-2xl text-slate-800 font-roboto mt-8 text-center">Buy this NFT for .01 ETH and it will feed 2 children and provide them with the necessities to facilitate a better education. </h3> */}
                    {/* <div className="text-center mt-10 mb-12"><a href="https://app.manifold.xyz/c/forthechildren" target="_blank" rel="noopener noreferrer" className="hover:bg-m2_orange bg-black text-white py-5 px-10 rounded-lg text-3xl">Purchase on Manifold for .01 ETH</a></div>

                    <div className="h-2 border-b border-slate-800 w-1/2 text-sm text-center mt-4 mb-8 mx-auto"></div> */}


                    <div className="text-2xl text-slate-800 font-roboto mt-4 text-center mb-20">
                        <p className="font-bold">You can contribute what you can here:</p>
                        <p>Paypal: <span className="text-m2_orange">ForChildrenWorldwide@gmail.com</span></p>
                        <p>Or</p>
                        <p>ETH wallet: <strong className="break-words text-xl text-m2_orange">0x88E3D86F94776DCC43Bc8a29C58267687446cbc3</strong></p>
                    </div>
                </div>
            </section>
        </>
    )
}
