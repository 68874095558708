
import About from './components/About';
import ClanMap from './components/ClanMap';
import StripClassic from './components/stripClassic';
import StripUrban from './components/stripUrban';
//import VIPCard from './components/VIPCard';
import UrbanBottom from './components/UrbanBottom';
//import CountdownBox from './components/CountdownBox';
import PandC from './components/PandC';
import Wutilities from './components/Wutilities';
import FoldArea from './components/FoldArea';

export default function Homepage(){
    return(
    <div className='bg-gradient-to-b from-[#c7c7c7] via-[#c6d4d3] to-[#8ea2a5] mx-auto'>
        {/* <CountdownBox/> */}
        <FoldArea/>
        <StripUrban/>
        <About/>
        <ClanMap/>
        <StripClassic/>
        {/* <VIPCard/> */}
        <PandC/>
        <Wutilities/>
        <UrbanBottom/>
    </div>
    )
}