export default function PandC(){
    return(
    <>
        {/* bg-none bg-[left_top_2rem] lg:bg-tiger_chinese_character bg-[length:100px_103px] bg-no-repeat */}
        <div className="max-w-7xl bg-birds_2 bg-contain bg-center bg-no-repeat mx-auto mb-2 md:mb-8">
            <div className="max-w-4xl mx-auto px-8 lg:px-0 bg-no-repeat">
                <div className="">
                    <h2 className='font-Shojumaru text-4xl md:text-6xl text-clan_burgundy text-center mb-6'>Perceptions & Codes</h2> 
                    <p className="mt-4 text-xl text-black">We are the pioneers who dare to do the unthinkable.</p>
                    <p className="mt-4 text-xl text-black">We're blazing through the fog of the physical and digital worlds as they clear.</p>
                    <p className="mt-4 text-xl text-black">We are a Village of individuals who have been making changes since day one, challenging old rules and speaking out. We are influencing web3 culture, yes we are. Our vision is to work together to build a metaverse village that can stand on its own and is managed by its citizens.</p>
                    <h3 className="mt-4 text-xl text-black font-semibold uppercase">
                    CODES:
                    </h3>
                    <p className="mt-4 text-xl text-black font-semibold">First and foremost, Citizens:</p>
                    <p className="mt-4 text-xl text-black">Citizens are at the center of everything. The moment we prioritize something other than citizens, we are on the verge of disaster. We will not allow this to happen because citizens will always be the Clan's top priority.</p>
                    
                    <p className="mt-4 text-xl text-black font-semibold">Only top quality:</p>
                    <p className="mt-4 text-xl text-black">Tiger Clan is inventive, brave, and unyielding. It is simple to follow trends and make incremental improvements. It's more difficult to be original and do your own thing. We forge our own way.</p>
                </div>
            </div>
        </div>
    </>
    );
}
