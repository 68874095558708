export default function UrbanBottom(){
    return (
        <>
         <div className="bg-urban_buildings bg-cover md:bg-contain bg-bottom md:[center_bottom] bg-no-repeat">
            <div className="bg-birds_one bg-cover md:bg-contain bg-center bg-no-repeat">
                <div className="max-w-7xl h-[700px] md:h-[800px] mt-10 pb-24 grid justify-items-top mx-auto bg-birds_2 bg-contain bg-center bg-no-repeat">
                    <div className="max-w-xl mx-auto pt-0 pb-8  px-8 lg:px-0 text-center">
                        <a href="https://wutigershop.com/" target="_blank" rel="noopner noreferrer" title="Wu Tiger Clan Merch">
                        <img src="/img/wtc-shirt.png" alt="Wu Tiger Clan Merchandise Store" className="mx-auto max-w-[350px]" />
                        <p className="text-center text-3xl text-clan_tiger_yellow font-semibold bg-[#161616] inline-block mx-auto p-5">Wu Tiger Clan Merch</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}