export default function About(){
    return(
    <>
        {/* bg-none bg-[left_top_2rem] lg:bg-tiger_chinese_character bg-[length:100px_103px] bg-no-repeat */}
        <div className="max-w-7xl bg-birds_2 bg-contain bg-center bg-no-repeat mx-auto mb-2 md:mb-8">
            <div className="max-w-4xl mx-auto px-8 lg:px-0 bg-no-repeat">
                <p className="mt-4 text-xl text-black">We are the trailblazers of the world. We dare to do what no one else will do. A new Village built together with its citizens for a better tomorrow.</p>
                <p className="mt-4 text-xl text-black">A collection of utility-enabled PFPs with a richly diverse and one-of-a-kind pool of rarity-powered traits. You own the IP rights to your PFP 100 percent. Furthermore, the longer you keep a Tiger Clan, the more benefits it unlocks, such as private club membership access to the village, keys to our world, Urban Wear collabs with top brands, NFT and IRL drops, live events and additional benefits that will reveal over time.</p>
            </div>
        </div>
    </>
    );
}