export default function Wutilities(){
    return(
    <>
        {/* bg-none bg-[left_top_2rem] lg:bg-tiger_chinese_character bg-[length:100px_103px] bg-no-repeat */}
        <div className="max-w-7xl bg-birds_2 bg-contain bg-center bg-no-repeat mx-auto mt-10 mb-2 md:mb-8">
            <div className="max-w-4xl mx-auto px-8 lg:px-0 bg-no-repeat">
                <div className="">
                    <h2 className='font-Shojumaru text-4xl md:text-6xl text-clan_burgundy text-center mb-6'>Tiger's Map & Wutilities</h2> 
                    <p className="mt-4 text-xl text-black">Things that are good take time. Metaverse movements cannot do things as quickly as IRL movements, which is both a benefit and a disadvantage. Leaders can exist in a Metaversal environment. Citizens will learn to lead and take control, but it will take time.</p>
                    <h3 className="mt-4 text-xl text-black font-semibold">
                    Holder benefits:
                    </h3>
                    <ol className="mt-4 list-none list-inside text-xl">
                        <li className="mb-6"><strong>01. IRL "Meet & Greet", special events & Pop-up shops</strong>
                            <ul className="list-disc list-inside ml-4 pl-0">
                                <li>Art Basel Miami & NFT Europe (Barcelona) are (WIP)</li>
                            </ul>
                        </li>
                        <li className="mb-6"><strong>02. Event exclusive drops for attendees (First Come First Serve)</strong></li>
                        <li className="mb-6"><strong>03. Charitable cause - saving the tigers and children's hospitals</strong></li>
                        <li className="mb-6"><strong>04. Collaboration with MEFaverse in their graphic novel</strong>
                            <ul className="list-disc list-inside ml-4 pl-0">
                                <li>Tigers will make guest appearances</li>
                                <li>We will host competitions so holders can get their specific PFP tigers in the graphic novel - details TBA</li>
                            </ul>
                        </li>
                        <li className="mb-6"><strong>05. Special NFT drops for VIP Early-Access Card Holders</strong></li>
                        <li className="mb-6"><strong>06. Exclusive art & music collab drops</strong>
                            <ul className="list-disc list-inside ml-4 pl-0">
                                <li>We will collaborate with NFT artists as well as NFT musicians to make special drops to our community that feature art AND music together</li>
                            </ul>
                        </li>
                        <li className="mb-6"><strong>07. HODL - longer you hold the more points you accumulate</strong>
                            <ul className="list-disc list-inside ml-4 pl-0">
                                <li>Points will be redeemable in the online token-gated merch shops</li>
                            </ul>
                        </li>
                        <li className="mb-6"><strong>08. Only holders of a WTC will have access to our merch shops as they will be token-gated and will offer limited edition merch including collab merch with A-list brands</strong></li>
                        <li className="mb-6"><strong>09. NFT Collab drops</strong>
                            <ul className="list-disc list-inside ml-4 pl-0">
                                <li>Ex. Scaredy Cubits x WTC (dropped)</li>
                                <li>More web3 brand collabs to come</li>
                            </ul>
                        </li>
                        <li className="mb-6"><strong>10. We will allow independent tigers to showcase their art in the online WTC gallery</strong></li>
                        <li className="mb-6"><strong>11. Wu Tiger Clan art prints (limited edition and FCFS) for WTC holders only</strong></li>
                        <li className="mb-6"><strong>12. Limited Edition comic book for holders</strong></li>
                        <li className="mb-6"><strong>13. Global murals to support the project and add value</strong>
                            <ul className="list-disc list-inside ml-4 pl-0">
                                <li>Argentina, Mexico, Columbia, Brazil, and Chile are (WIP)</li>
                                <li>If you or a friend is a graffiti or mural artist and would be interested in this program - reach out to us - we're interested in working with you!</li>
                            </ul>
                        </li>
                        <li className="mb-6"><strong>14. Exclusive Holder-Only Access to future Tiger ecosystem collections</strong>
                            <ul className="list-disc list-inside ml-4 pl-0">
                                <li>These will have high secondary market value due to the barrier of entry (only way to get access to these is to be a WTC holder)</li>
                            </ul>
                        </li>
                    </ol>
                    <p className="mt-12 text-xl md:text-2xl text-black font-semibold text-center">Follow the Journey on <a href="https://www.twitter.com/wutigerclan" target="_blank" rel="noopener noreferrer" className="text-black underline">Twitter</a></p>
                </div>
            </div>
        </div>
    </>
    );
}
