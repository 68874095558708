export default function FoldArea(){
    return (
        <>
        <div className="bg-classic_buildings bg-cover md:bg-contain bg-top bg-no-repeat">
            <div className="bg-birds_one bg-cover md:bg-contain bg-center bg-no-repeat">
                <div className="max-w-7xl pt-12 md:pt-24 pb-0 grid justify-items-center mx-auto">
                    <div className="max-w-sm mx-auto">
                        <img src="/img/v2/cloud-tiger.png" alt="" className="w-56 md:w-auto" />
                    </div>
                </div>
                <div className="max-w-7xl px-8 md:px-0 pt-6 pb-8 md:pb-0 grid justify-items-center mx-auto">
                    <h1 className='font-Shojumaru text-4xl md:text-7xl text-clan_burgundy'>Wu Tiger Clan</h1>
                    <p className="mt-2 text-xl md:text-2xl text-center text-black max-w-3xl">We are the trailblazers of the metaverse. We dare to do what no one else will do. A new Village built together with its citizens for a better tomorrow.</p>
                </div>
            </div>            
        </div>
        </>
    )
}